<template>
  <section class="w-full box p-4" id="filtro-rips">
    <div v-if="loading" class="rounded-md icono-carga flex justify-center items-center">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)"/>
    </div>
    <div class="accordion-item" v-else>
      <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show mt-2"
           aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div class="grid grid-cols-6 gap-x-6 gap-y-2">
            <div class="col-span-6 sm:col-span-2">
              <label for="start_year" class="block text-sm font-medium text-gray-700">Año:</label>
              <select class="form-select form-select-sm mt-1" id="start_year" v-model="filters.year">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="(row,k) in anos" :key="k" :value="row">{{ row }}</option>
              </select>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="start_month" class="block text-sm font-medium text-gray-700">Mes Inicial:</label>
              <select class="form-select form-select-sm mt-1" id="start_month" v-model="filters.month_start">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="(row,k) in months" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="end_month" class="block text-sm font-medium text-gray-700">Mes Final:</label>
              <select class="form-select form-select-sm mt-1" id="end_month" v-model="filters.month_end">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="(row,k) in filterMonths" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed } from 'vue'
import InformesRipsService from '../services'

export default {
  name: 'FilterRips',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const _informesRipsService = new InformesRipsService()
    const months = [
      { id: '01', name: 'Enero' },
      { id: '02', name: 'Febrero' },
      { id: '03', name: 'Marzo' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Mayo' },
      { id: '06', name: 'Junio' },
      { id: '07', name: 'Julio' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Septiembre' },
      { id: '10', name: 'Octubre' },
      { id: '11', name: 'Noviembre' },
      { id: '12', name: 'Diciembre' }
    ]

    const filterMonths = computed(() => {
      return months.filter((itemMonth) => {
        return parseInt(props.modelValue.month_start) <= parseInt(itemMonth.id)
      })
    })

    const getYears = () => {
      const years = []
      const yearNow = new Date().getFullYear()
      for (let index = 0; index < 6; index++) {
        years.push(yearNow - index)
      }
      return years
    }
    const loading = ref()
    return {
      filters: props.modelValue,
      filterMonths,
      months,
      loading,
      anos: getYears(),
      _informesRipsService
    }
  }
}
</script>
<style>
.btn-search {
  width: 90px;
  margin-top:20px;
  height: 35px;
}
.btn-div-center {
  display: flex;
  justify-content: center;
}
</style>
